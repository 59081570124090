import { useState, useEffect } from 'react';
import { sysAPI } from '../../apis/SysAPI';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import moment from 'moment';
import { Modal, Radio, Message, Segment, Button, Form, Dropdown, Input, Select } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import FormEnterprisePermissions from '../tabs/company/enteprise-permissions/FormEnterprisePermissions';
import enterprisePermissionsDefault from '../tabs/company/enteprise-permissions/AllPermissions';
import { responseError } from '../../helpers';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../actions/snackbar.actions';

export const AddCompanyModal = ({ modalState, setModalState }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [type, setType] = useState('trial');
  const [pocs, setPocs] = useState([]);
  const [company, setCompany] = useState([]);
  const [poc, setPoc] = useState('');
  const [modelType, setModelType] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [renewal, setRenewal] = useState('');
  const [contractEndDate, setContractEndDate] = useState('');
  const [enterprisePermissions, setEnterprisePermissions] = useState([]);

  const modelTypesOptions = [
    {
      text: 'Legacy: Unlimited Tax, pay per Estate/Auto/Home Snapshot',
      value: 'legacy',
      // disabled: true,
    },
    {
      text: 'Part Limited: Firmwide Adoption Unlimited Tax, 10 Estate/5 Auto/5 Home',
      value: 'adoption',
    },
    {
      text: 'Full Limited: 35 Tax/ 10 Estate/ 5 Auto/ 5 Home',
      value: 'limited',
    },
  ];

  const cancelButton = () => {
    setType('trial');
    setCompany('');
    setPoc('');
    setModelType('');
    setContactName('');
    setContactEmail('');
    setModalState(false);
  }

  const addCompany = async () => {
    const auxPermissions = {}
    try {
      for (const per of enterprisePermissionsDefault) {
        if (!auxPermissions[per.key]) {
          auxPermissions[per.key] = {
            fields: [],
            isActive: false,
          };
        }
      }
      for (const per of enterprisePermissions) {
        const [key, field] = per.split(':');
        if (auxPermissions[key]) {
          auxPermissions[key].isActive = true;
          auxPermissions[key].fields.push(field);
        } else {
          console.warn(`key not found => ${key}`)
        }
      }
    } catch (error) {
      responseError(error, undefined, undefined, dispatch);
    }
    const body = {
      name: company,
      poc: poc,
      modelType: type,
      uploadModelType: modelType,
      renewalDate: type === 'purchased' ? renewal : moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD').toString(),
      contractEndDate: type === 'purchased' ? contractEndDate : moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD').toString(),
      logo: 'https://storage.googleapis.com/fpalpha-assets/company%20logos/fpalpha/fpalpha1_CMYK.png',
      mainContact: {
        name: contactName,
        email: contactEmail,
      },
      enterprisePermissions: auxPermissions
    };
    try {
      const {data:company} = await advisorAPI.post('companies/', body);
      console.log({company});
      if(company?._id){
        // eslint-disable-next-line no-restricted-globals
        const goCompany = confirm(`The company "${company.name}" was created. Do you want view the profile?`);
        if(goCompany){
          history.push(`/dashboard/companies/${company?._id}`);
        }
      }

      cancelButton();
      dispatch(setSnackbar({message: "Company created"}))
    } catch (error) {
      responseError(error, undefined, undefined, dispatch);
    }
  };

  const toggleTypeChangePermissions = () => {
    const auxPermissions = [];
    if(type === "trial"){
      for (const defRol of enterprisePermissionsDefault) {
        for(const field of defRol.fields){
          if(!["areas", "settings"].includes(defRol.key)){
            auxPermissions.push(`${defRol.key}:${field.value}`);
          }
        }
      }
      auxPermissions.push(`areas:auto`, `areas:home`, `areas:estate`, `areas:fulltax`)
    }else{
      for (const defRol of enterprisePermissionsDefault) {
        for(const field of defRol.fields){
          if(!["settings"].includes(defRol.key)){
            auxPermissions.push(`${defRol.key}:${field.value}`);
          }
        }
      }
    }
    setEnterprisePermissions(auxPermissions)
  }

  useEffect(() => {
    sysAPI.get('/companies/pocs').then(response => {
        setPocs(response.data.map(itm => ({ key: itm?._id || itm?.name, value: itm?._id || itm?.name, text: itm?.name })));
    });
  }, []);

  useEffect(() => {
    toggleTypeChangePermissions();

    // eslint-disable-next-line
  },[type])
  return (
    <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
      <Modal.Header>Create Company</Modal.Header>

      <Modal.Content>
        <Segment>
          <Form>
            <Form.Group style={{marginBottom: 0}}>
              <Form.Field control={Radio} label="Trial" checked={type === 'trial'} onChange={() => setType('trial')} />
              <Form.Field control={Radio} label="Purchased" checked={type === 'purchased'} onChange={() => setType('purchased')} />
            </Form.Group>

            {type === 'trial' && <>
              <Message color="teal">
                This company will have 10 days of trial version.
              </Message>
            </>}
          </Form>
        </Segment>

        {type === 'purchased' &&
          <>
            <Segment>
              <div style={{ width: '100%' }}>
                <Form.Field
                  control={Input}
                  type="date"
                  fluid
                  label="Renewal date"
                  value={renewal}
                  onChange={(_, val) => setRenewal(val.value)}
                  min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                />
              </div>
            </Segment>
            <Segment>
              <div style={{ width: '100%' }}>
                <Form.Field
                  control={Input}
                  type="date"
                  fluid
                  label="Contract End Date"
                  value={contractEndDate}
                  onChange={(_, val) => setContractEndDate(val.value)}
                  min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                />
              </div>
            </Segment>
          </>
        }

        <Segment>
          <Form>
            <Input
              icon='building'
              iconPosition='left'
              labelPosition='right'
              placeholder='Company'
              style={{marginBottom:"10px"}}
              fluid
              value={company}
              onChange={(_, val) => { setCompany(val.value) }}
            />
            <Select
              placeholder='POC'
              iconPosition='left'
              labelPosition='right'
              onChange={(_, val) => { setPoc(val.value) }}
              options={pocs}
              value={poc}
              fluid
              search
              labeled
            />
          </Form>
        </Segment>

        <Segment>
          <Dropdown
            placeholder="Model type"
            className="icon"
            options={modelTypesOptions}
            value={modelType}
            onChange={(_, val) => setModelType(val.value)}
            icon='tag'
            labeled
            button
            fluid
            selection
            search
          />
        </Segment>

        <Segment>
          <h4>Main Contact</h4>
          <Form>
            <div style={{ width: '100%', marginBottom: '12px' }}>
              <Form.Field control={Input} fluid label="Name" value={contactName} onChange={(_, val) => setContactName(val.value)} />
            </div>
            <div style={{ width: '100%' }}>
              <Form.Field control={Input} fluid type="email" label="Email" value={contactEmail} onChange={(_, val) => setContactEmail(val.value)} />
            </div>
          </Form>
        </Segment>

        <Segment>
          <h4>Enteprise Permissions</h4>
          <Form>
            <FormEnterprisePermissions
              permissions={enterprisePermissions}
              setPermissions={setEnterprisePermissions}
              defaultPermissions={enterprisePermissionsDefault}
              areasToShow={["areas", "settings"]}
              minWidth='120px'
              isToggle={false}
            />
          </Form>
        </Segment>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={cancelButton}>Cancel</Button>
        <Button
          color="green"
          onClick={addCompany}
          disabled={!(!!(
            company
            && modelType
            && poc
            && contactName
            && contactEmail
            && (type === 'trial' || (type === 'purchased' && renewal))
          ))}
        >
          Create account
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
