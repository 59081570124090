import { Button, Checkbox } from "semantic-ui-react";
import enterprisePermissionsDefault from "./AllPermissions"

const FormEnterprisePermissions = ({permissions, setPermissions, defaultPermissions=enterprisePermissionsDefault, areasToShow=enterprisePermissionsDefault.map(e => e.key), minWidth="200px", maxWidth="1fr", isToggle=true}) => {
  const handleUpdatePermissions = (permission) => {
    let newPermissions = JSON.parse(JSON.stringify(permissions));
    if (newPermissions.includes(permission)) {
      newPermissions = newPermissions.filter((per) => per !== permission);
    } else {
      newPermissions.push(permission);
    }
    setPermissions(newPermissions);
  };

  const handleSelectAll = () => {
    let allPermissions = [];
    if (permissions.length <= 0) {
      for (const defRol of defaultPermissions) {
        for(const field of defRol.fields){
          allPermissions.push(`${defRol.key}:${field.value}`);
        }
      }
    }
    setPermissions(allPermissions);
  };

  const handleAddFontSize = (isChecked) => {
    const styles = {
      fontSize: '15px'
    }

    if(isChecked){
      styles.fontWeight = "bold";
      styles.fontSize = "'17px'";
    }

    return styles
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{marginBottom:"10px"}}>
            <Button onClick={() => handleSelectAll()}>{permissions.length > 0 ? 'Unselect' : 'Select'} All</Button>
          </div>
        </div>
        <div
          style={{
            //maxHeight: '600px',
            overflowY: 'auto',
            //border: "1px solid #ccc",
            padding: '5px',
          }}
        >
          {defaultPermissions.map((area, index) => areasToShow.includes(area.key) && (
            <div key={index}>
              <h3 style={{ paddingTop: '10px' }}>{area.title}</h3>
              <div style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, ${maxWidth}))`, gap: '10px' }}>
                {area.fields.map((field, index_2) => (
                  <div key={index_2} style ={{
                    backgroundColor: "#0000002b",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                    padding: "5px 10px",
                    margin: "2px 5px",
                  }}>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <div>
                      <Checkbox
                        toggle={isToggle}
                        label={field.text}
                        onClick={() => handleUpdatePermissions(`${area.key}:${field.value}`)}
                        checked={permissions.includes(`${area.key}:${field.value}`)}
                        style={handleAddFontSize(permissions.includes(`${area.key}:${field.value}`))}
                      />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {index + 1 < defaultPermissions.length && <hr />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FormEnterprisePermissions;