import { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { advisorAPI } from '../../../../apis/AdvisorsAPI';
import { useDispatch, useSelector } from 'react-redux';
import enterprisePermissionsDefault from './AllPermissions';
import FormEnterprisePermissions from './FormEnterprisePermissions';
import { responseError } from '../../../../helpers';
import { setSnackbar } from '../../../../actions/snackbar.actions';

const EnterprisePermissions = () => {
  const dispatch = useDispatch();
  const [enterprisePermissions, setEnterprisePermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const companyProfile = useSelector((state) => state.currentCompany);

  const getInfo = async () => {
    setLoading(true);
    try {
      const auxPermissions = [];
      const { data } = await advisorAPI.get(`/companies/${companyProfile._id}/permissions-enterprise`);
      for (const key in data.enterprisePermissions) {
        for (const field of data.enterprisePermissions[key]?.fields || []) {
          auxPermissions.push(`${key}:${field}`);
        }
      }
      setEnterprisePermissions(auxPermissions);
    } catch (error) {
      responseError(error, undefined, undefined, dispatch);
    }
    setLoading(false);
  };

  const savePermissions = async () => {
    setLoadingSave(true);
    try {
      const auxPermissions = {};
      for (const per of enterprisePermissionsDefault) {
        if (!auxPermissions[per.key]) {
          auxPermissions[per.key] = {
            fields: [],
            isActive: false,
          };
        }
      }
      for (const per of enterprisePermissions) {
        const [key, field] = per.split(':');
        if (auxPermissions[key]) {
          auxPermissions[key].isActive = true;
          auxPermissions[key].fields.push(field);
        } else {
          console.warn(`key not found => ${key}`)
        }
      }
      const {data} = await advisorAPI.put(`/companies/${companyProfile._id}/permissions-enterprise`, {enterprisePermissions: auxPermissions});
      console.log({data})
      dispatch(setSnackbar({message: data.message || 'Success'}));
    } catch (error) {
      responseError(error, undefined, undefined, dispatch);
    }
    setLoadingSave(false);
  };

  useEffect(() => {
    if (companyProfile?._id) {
      getInfo();
    }

    // eslint-disable-next-line
  }, [companyProfile?._id]);

  const Header = () => {
    return (
      <div>
        <h2>Enteprise Permissions</h2>
      </div>
    );
  };

  if (loading) {
    return (
      <>
        <Header />
        <Icon loading name="asterisk" /> Loading ...
      </>
    );
  }

  if (!enterprisePermissions) {
    return (
      <>
        <Header />
        Something was wrong...
      </>
    );
  }
  return (
    <>
      <Header />
      <div>
        <FormEnterprisePermissions
          permissions={enterprisePermissions}
          setPermissions={setEnterprisePermissions}
          defaultPermissions={enterprisePermissionsDefault}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button loading={loadingSave} disabled={loadingSave} color="green" onClick={() => savePermissions()}>
          Save changes
        </Button>
      </div>
    </>
  );
};

export default EnterprisePermissions;
