export const areasSimpleComplexNameMatch = {
  basictax: 'Basic Tax',
  auto: 'Auto Insurance',
  home: 'Home Insurance',
  disability: 'Disability Insurance',
  estate: 'Estate Planning',
  identity: 'Identity Theft',
  medical: 'Medical Insurance',
  student: 'Student Debt',
  general: 'General Questionnaire',
  fulltax: 'Tax Planning',
  ltc: 'Long Term Care',
  mortgage: 'Mortgage',
  life: 'Life Insurance',
  divorce: 'Divorce Planning',
  property: 'Property Tax',
  expense: 'Expense Analysis',
  elder: 'Elder Care',
  creditor: 'Creditor Protection',
  crossborder: 'Cross Border Planning',
  basicestate: 'Basic Estate',
  fulltaxnewengine: 'Tax New Engine',
  retirement: 'Retirement',
  investment: 'Investment',

  prospects: "Prospects",
  reports_access: "Reports Access",
  task_manager_access: "Task Manager Access",
  edit_insights: "Edit Insights",
  show_actions: "Show Actions",
  insights_access: "Insights Access",
  custom_insights: "Custom Insights",
  company_library: "Company Library",
  roth: "Roth",
  estatelab: "Estate Lab",
  tax_projector: "Tax Projector",
  taxSnapshot: "Tax Snapshot",
  estateSnapshot: "Estate Snapshot",
  homeSnapshot: "Home Snapshot",
  autoSnapshot: "Auto Snapshot"
};