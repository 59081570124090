import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { fpalphaAPI } from "../../../apis/FPAlphaAPI";
import { useHistory } from 'react-router-dom';

const RemoveCompany = ({company}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassowrd, setShowPassword] = useState(false);
  const [password, setPassword] = useState("")
  const me = useSelector(state => state.me);

  const handleRemove = async () => {
    setLoader(true)
    try {
      const {data} = await fpalphaAPI.post(`/tools/company/delete`, {
        companies: [company.name],
        password_confirmation: password
      })
      alert(data?.message || "The company was deleted succefully.");

      history.push("/dashboard/companies")
    } catch (error) {
      console.log({error})
      const messageError = error.response ? error.response.data.message : error.message || "Error"
      alert(messageError)
    }
    setLoader(false)
  }

  if(!me?.permissions.includes("companies:delete_company")){
    return <div></div>
  }

  return (
    <div>
      <Button color="red" onClick={() => setOpenModal(!openModal)}>
        Delete Company
      </Button>
      <Modal open={openModal} size="tiny">
        <Modal.Header>
          Are you sure delete "{company.name}" company?
        </Modal.Header>
        <Modal.Content>
          <div>
            <p>
              Please input your password to confirm this action.
            </p>
            <Form>
              <Form.Field
                control={Input}
                action={{ icon: showPassowrd ? 'eye' : 'eye slash', onClick: () => setShowPassword(!showPassowrd) }} 
                label="Password"
                type={showPassowrd ? 'text' : 'password'}
                defaultValue={password}
                fluid
                onChange={(e) => setPassword(e?.target?.value)}
              />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)} disabled={loader}>
            Cancel
          </Button>
          <Button onClick={() => handleRemove()} color="red" disabled={loader || password === ""}>
            {loader ? 'Loading...' : 'Confirm'}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default RemoveCompany;
