import { useEffect, useState } from "react";
import { Button, Checkbox, Icon, Modal, Segment, Select } from "semantic-ui-react";
import { sysAPI } from "../../../apis/SysAPI";
import { fetchCurrentAdvisorProfile } from "../../../actions/advisor.actions";
import { useDispatch, useSelector } from "react-redux";

const EnableDisableFeatures = ({advisorProfile}) => {
    const me = useSelector((state) => state.me);
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [disabledFeatures, setDisabledFeatures] = useState([]);
    const [areasAvailable, setAreasAvailable] = useState({});


    const optionsDisableFeature = [
        { key: 1, value: "clientPortal", text: "Client Portal" },
        { key: 2, value: "prospect", text: "Prospect" },
    ]

    const optionsAreas = [
        { text: "Auto", prop: "hideAuto"},
        { text: "Creditor", prop: "hideCreditor"},
        { text: "Cross Border", prop: "hideCrossborder"},
        { text: "Disability", prop: "hideDisability"},
        { text: "Divorce Planning", prop: "hideDivorce"},
        { text: "Elder care", prop: "hideElder"},
        { text: "Estate Planning", prop: "hideEstate"},
        { text: "Expense", prop: "hideExpense"},
        { text: "Home", prop: "hideHome"},
        { text: "Identity", prop: "hideIdentity"},
        { text: "Life Planning", prop: "hideLife"},
        { text: "LTC", prop: "hideLtc"},
        { text: "Medical", prop: "hideMedical"},
        { text: "Mortgage", prop: "hideMortgage"},
        { text: "Tax Planning", prop: "hideFulltax"},
        { text: "Property", prop: "hideProperty"},
        { text: "Student Loan", prop: "hideStudent"},
    ]

    const handleSave = async () => {
        setLoader(true)
        try {
            const data__ = {
                disabledFeatures,
                ...areasAvailable
            }

            const {data} = await sysAPI.post(`/advisor/${advisorProfile._id}/update`, data__)
            console.log({data})
            setOpenModal(false)
            dispatch(fetchCurrentAdvisorProfile(advisorProfile._id));
        } catch (error) {
            const messageError = error?.response?.data?.message || error?.message || "Something was wrong"
            console.log({error})
            alert(messageError)
        }
        setLoader(false)
    }

    const handleChangeArea = (value, prop) => {
        const auxAreasAvailable = {...areasAvailable};
        auxAreasAvailable[prop] = !value
        setAreasAvailable(auxAreasAvailable)
    }

    const defaultContig = () => {
        if(advisorProfile?.disabledFeatures){
            setDisabledFeatures(advisorProfile?.disabledFeatures || []);
        }
    
        const auxAreasAvailable = {}
        for(const area_ of optionsAreas){
            auxAreasAvailable[area_.prop] = advisorProfile[area_.prop] || false
        }
        setAreasAvailable(auxAreasAvailable)
    }

    useEffect(() => {
        defaultContig();

        // eslint-disable-next-line
    },[advisorProfile, openModal]);

    return <>
    {(me.permissions.includes("advisor:disabled_features") || me.permissions.includes("advisor:changes_areas")) &&
        <Button icon labelPosition='left' onClick={() => setOpenModal(!openModal)} color="blue">
            <Icon size='large' name='star' />
            Enable/Disable Features
        </Button>
    }

        <Modal open={openModal} size="tiny">
            <Modal.Header>
                Enable or Disable Features
            </Modal.Header>
            <Modal.Content>
                {me.permissions.includes("advisor:changes_areas") &&
                    <Segment>
                        <h2>Select Areas Available</h2>
                        <div>
                            {optionsAreas.map((area, index) => (
                                <div>
                                    <Checkbox label={area.text} checked={!areasAvailable[area.prop]} onClick={(e, data) => handleChangeArea(data.checked, area.prop)} />
                                </div>
                            ))}
                        </div>
                    </Segment>
                }
                {me.permissions.includes("advisor:disabled_features") &&
                    <Segment>
                        <h2>Select a feature to disable</h2>
                        <div>
                            <Select options={optionsDisableFeature} fluid multiple defaultValue={disabledFeatures} onChange={(_,{value}) => setDisabledFeatures(value)} />
                        </div>
                    </Segment>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Close</Button>
                <Button onClick={() => handleSave()} color="green" loading={loader}>Save</Button>
            </Modal.Actions>

        </Modal>
    </>
}

export default EnableDisableFeatures;