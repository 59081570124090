import { useEffect, useState } from "react";
import { responseError } from "../../../helpers";
import { advisorAPI } from "../../../apis/AdvisorsAPI";
import { useSelector } from "react-redux";
import { Button, Label } from "semantic-ui-react";
import { areasSimpleComplexNameMatch } from "../../../helpers/nameAreas";

const TabShowPermissions = ({activeTab}) => {
  const [loading, setLoading] = useState(false)
  const [permissionsFromAllAdvisorGroups, setPermissionsFromAllAdvisorGroups] = useState({})
  const [permissionsOfTheAdvisor, setPermissionsOfTheAdvisor] = useState({})
  const [showAreas, setShowAreas] = useState({})
  const advisorProfile = useSelector(state => state.currentAdvisorProfile);

  const getPermissions = async () => {
    setLoading(true)
    try {
      const {data} = await advisorAPI.get(`/${advisorProfile?._id}/enterprise-permissions`);
      setPermissionsFromAllAdvisorGroups(data.permissionsActiveFromAllAdvisorGroupsSectioned);
      setPermissionsOfTheAdvisor(data.advisorPermissions);
      console.log({data})
    } catch (error) {
      responseError(error);
    }
    setLoading(false)
  }

  useEffect(() => {
    if(advisorProfile?._id){
      getPermissions();
    }

    // eslint-disable-next-line
  },[advisorProfile])

  return <>
    {activeTab === "Permissions" && <>
      {loading ? <>Loading...</> : <>
        <div className="flex-fpalpha " style={{gap: "15px"}} >
          <div className="card-border-line" style={{width: "50%"}}>
            <h3>
              User Permissions
            </h3>
            <div className="grid_fp">
              {Object.keys(permissionsOfTheAdvisor).map((key,index) => (
                <div key={index}>
                  <div>
                    <strong>{key} ({permissionsOfTheAdvisor[key].fields.length || 0})</strong>
                  </div>
                  <Button basic className='helper_button' size='mini' onClick={() => setShowAreas({...showAreas, [key]: !showAreas[key]})}>{showAreas[key] ? 'Show' : 'Hide'}</Button>
                  {!showAreas[key] &&
                    <div className="flex-wrap_fp" style={{marginTop: "5px"}}>
                      {permissionsOfTheAdvisor[key].fields.map((field, index2) => (
                        <div key={index2}>
                          <Label>
                            {areasSimpleComplexNameMatch[field] || field}
                          </Label>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>

          <div className="card-border-line" style={{width: "50%"}}>
            <h3>
              Global Permissions
            </h3>
            <div className="grid_fp">
              {Object.keys(permissionsFromAllAdvisorGroups).map((key,index) => (
                <div key={index}>
                  <div>
                    <strong>{key} ({permissionsFromAllAdvisorGroups[key].fields.length || 0})</strong>
                  </div>
                  <Button basic className='helper_button' size='mini' onClick={() => setShowAreas({...showAreas, [key]: !showAreas[key]})}>{showAreas[key] ? 'Show' : 'Hide'}</Button>
                  {!showAreas[key] &&
                    <div className="flex-wrap_fp" style={{marginTop: "5px"}}>
                      {permissionsFromAllAdvisorGroups[key].fields.map((field, index2) => (
                        <div key={index2}>
                          <Label>
                            {areasSimpleComplexNameMatch[field] || field}
                          </Label>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </>}
    </>}
  </>
}

export default TabShowPermissions;