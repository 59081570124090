import { Icon, Popup, Table, Button, Modal, Select, MessageHeader, Message } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { prettyDate } from '../../../helpers/moment-helpers';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { copyToClipboard, responseError } from '../../../helpers';
import { AddAdvisorModal } from '../../modal/AddAdvisorModal';
import { advisorAPI } from '../../../apis/AdvisorsAPI';
import { fetchCurrentCompany } from '../../../actions/company.actions';

const CompanyAdvisorsTab = ({ activeTab }) => {
  const me = useSelector((state) => state.me);

  const companyProfile = useSelector((state) => state.currentCompany);
  console.log({companyProfile})
  const [showTabActions, setShowTabActions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    try {
      const {data:roles} = await advisorAPI.get("/enterprise-settings/role");
      console.log({roles})
      setRoles(roles?.data?.map(e => {
        return {
          key: e.key,
          value: e._id,
          text: e.name,
        }
      }));
    } catch (error) {
      console.error({error})
    }
  }

  useEffect(() => {
    const hasPermissionsToShowActions = me?.permissions?.filter((p) => ['advisors:copy_email', 'advisors:copy_id', "advisor:update_enterprise_roles"].includes(p)) || [];
    if (hasPermissionsToShowActions.length > 0) {
      setShowTabActions(true);
      getRoles();
    }
  }, [me]);

  return (
    <div>
      {activeTab === 'advisors' && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>All Advisors ({companyProfile?.advisorList?.length})</h3>
            <div>
              {me?.permissions?.includes('advisors:add_advisor') && (
                <Button color="blue" onClick={() => setShowModal(true)}>
                  Add Advisor
                </Button>
              )}
            </div>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Full Name</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                {/* <Table.HeaderCell>POC</Table.HeaderCell> */}
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>2FA</Table.HeaderCell>
                <Table.HeaderCell>License Expires</Table.HeaderCell>
                <Table.HeaderCell>Last Login</Table.HeaderCell>
                {showTabActions && (
                  <>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {companyProfile?.advisorList?.map((advisor, index) => (
                <Table.Row key={index}>
                  <AdvisorRow advisor={advisor} me={me} showTabActions={showTabActions} roles={roles} />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <AddAdvisorModal
            modalState={showModal}
            setModalState={(val) => setShowModal(val)}
            preselectedCompany={companyProfile?._id}
            companyType={companyProfile?.modelType}
          />
        </div>
      )}
    </div>
  );
};

const AdvisorRow = ({ advisor, showTabActions, me, roles }) => {
  const [isCopied, setIsCopied] = useState(false);
  const history = useHistory();
  return (
    <>
      <Table.Cell>
        <div style={{ cursor: 'pointer' }} onClick={() => history.push(`/dashboard/advisors/${advisor._id}`)}>
          {advisor?.fullName}
        </div>
      </Table.Cell>
      <Table.Cell>{advisor?.email}</Table.Cell>
      <Table.Cell>{advisor?.enterpriseRole?.name}</Table.Cell>
      {/* <Table.Cell>{companyProfile?.poc?.fullName}</Table.Cell> */}
      <Table.Cell>{advisor?.status}</Table.Cell>
      <Table.Cell>{advisor?.hasEnabled2FA ? 'Active' : 'Inactive'}</Table.Cell>
      <Table.Cell>{prettyDate({ date: advisor?.licenseExpiresIn })}</Table.Cell>
      <Table.Cell>{prettyDate({ date: advisor?.activity?.lastLogin })}</Table.Cell>
      {showTabActions && (
        <>
          <Table.Cell>
            {me?.permissions?.includes('advisor:update_enterprise_roles') && (
              <>
                <ChangeRole advisor={advisor} roles={roles} />
              </>
            )}
            {me?.permissions?.includes('advisors:copy_email') && (
              <>
                <Popup
                  on={['hover']}
                  trigger={
                    <Icon
                      name="at"
                      style={{ cursor: 'pointer' }}
                      size="large"
                      onClick={() => {
                        copyToClipboard(`${advisor.email}`, false);
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 1000);
                      }}
                    />
                  }
                  content={isCopied ? 'Copied' : 'Click to copy Email'}
                  inverted
                />
              </>
            )}
            {me?.permissions?.includes('advisors:copy_id') && (
              <>
                <Popup
                  on="hover"
                  trigger={
                    <Icon
                      name="barcode"
                      style={{ cursor: 'pointer' }}
                      size="large"
                      onClick={() => {
                        copyToClipboard(`${advisor._id}`, false);
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 1000);
                      }}
                    />
                  }
                  content={isCopied ? 'Copied' : 'Click to copy ID'}
                  inverted
                />
              </>
            )}
          </Table.Cell>
        </>
      )}
    </>
  );
};

const ChangeRole = ({advisor, roles}) => {
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.currentCompany);
  const [openModal, setOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [role, setRole] = useState(advisor?.enterpriseRole?._id || "");

  const updateAdvisorRole = async () => {
    setLoader(true)
    try {
       const {data} = await advisorAPI.patch(`/${advisor._id}`,{
        enterpriseRole: role
      });
      dispatch(fetchCurrentCompany(companyProfile._id));

      setOpenModal(false);
      console.log({data})
    } catch (error) {
      responseError(error, undefined, "AdvisorTab.ChangeRole.updateAdvisorRole");
    }
    setLoader(false)
  }

  return <>
    <Popup
      on="hover"
      trigger={
        <Icon
          name="setting"
          style={{ cursor: 'pointer' }}
          size="large"
          onClick={() => setOpenModal(!openModal)}
        />
      }
      content="Update Role"
      inverted
    />

    <Modal open={openModal}>
      <Modal.Header>Update Role for {advisor.fullName}</Modal.Header>
      <Modal.Content>
      <div>
          Role:
          <Select value={role} disabled={advisor?.enterpriseRole?.key === "super_admin"} labeled fluid options={roles} placeholder='Role' onChange={(e, {value}) => setRole(value)} />

          {advisor?.enterpriseRole?.key === "super_admin" && <>
            <Message warning>
                <MessageHeader>Warning, we can't change the role!</MessageHeader>
                <p><strong>{advisor.fullName}</strong> is the Super Admin. You need to change another advisor to be the new Super Admin, and <strong>{advisor.fullName}</strong> will become an Admin</p>
            </Message>
          </>}

          {advisor?.enterpriseRole?.key !== "super_admin" && roles?.find(e => e.value === role)?.key === "super_admin" && <>
            <Message warning>
                <MessageHeader>Warning!</MessageHeader>
                <p>If a Super Admin already exists, they will become an Admin, and {advisor.fullName} will become the new Super Admin.</p>
            </Message>
          </>}
      </div>

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpenModal(false)}>Cancel</Button>
        <Button onClick={() => updateAdvisorRole()} loading={loader} color='green' disabled={advisor?.enterpriseRole?.key === "super_admin"}>Update</Button>
      </Modal.Actions>
    </Modal>

  </>
}
export default CompanyAdvisorsTab;
