import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Container, Button, Table, Tab, Label, Pagination, Message, Header, Dropdown, Checkbox, Loader, /* Dimmer */ } from 'semantic-ui-react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fetchAreasExtractions, fetchAreasRecommendations } from '../actions/metric.actions';
import { sysAPI } from '../apis/SysAPI';
import { TabContainer } from '../components/shared/TabContainer';
import { DashboardTemplate } from "../templates/DashboardTemplate";

const ALL_AREAS = {
    'all': 'All Areas',
    'estate': 'Estate Planning',
    'auto': 'Auto',
    'crossborder': 'Cross Border',
    'disability': 'Disability',
    'home': 'Home',
    'medical': 'Medical',
    'identity': 'Identity',
    'student': 'Student Loan',
    'expense': 'Expense',
    'fulltax': 'Tax Planning',
    'ltc': 'LTC',
    'mortgage': 'Mortgage',
    'life': 'Life Planning',
    'divorce': 'Divorce Planning',
    'property': 'Property',
    'creditor': 'Creditor',
    'elder': 'Elder care',
};

const AreaExtractions = ({ items }) => {
    const _items = items.sort((a, b) => {
        return moment(b.date).diff(a.date);
    });

    const dateFormatting = (date) => {
        return date && moment(date).isValid()
            ? moment(date).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A';
    }

    const timeDelta = (timestamps) => {
        const start = timestamps?.start;
        const end = timestamps?.end;

        if (!start || !end || !moment(start).isValid() || !moment(end).isValid()) {
            return 'N/A';
        }

        const delta = moment(end).diff(start);
        const duration = moment.duration(delta);
        return `${duration.minutes()}m ${duration.seconds()}s`;
    }

    return (
        <Tab.Pane>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Advisor</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Start</Table.HeaderCell>
                        <Table.HeaderCell>End</Table.HeaderCell>
                        <Table.HeaderCell>Time Delta</Table.HeaderCell>
                        <Table.HeaderCell>Result</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_items?.map(item => {
                        return <Table.Row data-stamps={item.timestamps ? JSON.stringify(item.timestamps) : '{}'}>
                            <Table.Cell>{item?.advisor?.fullName}</Table.Cell>
                            <Table.Cell>{item.client ? `${item.client.firstName} ${item.client.lastName}` : 'N/A'}</Table.Cell>
                            <Table.Cell>{item.data.year ? item.data.year : 'N/A'}</Table.Cell>
                            <Table.Cell>{moment(item?.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                            <Table.Cell>{dateFormatting(item.data.timestamps?.start)}</Table.Cell>
                            <Table.Cell>{dateFormatting(item.data.timestamps?.end)}</Table.Cell>
                            <Table.Cell>{timeDelta(item.data.timestamps)}</Table.Cell>
                            <Table.Cell>{item.data.isValid ? <Label color='green'>Valid</Label> : <Label color='red'>Invalid</Label>}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        </Tab.Pane>
    )
}

const AreaRecommendations = ({ items, area }) => {
    const _items = items;

    const dateFormatting = (date) => {
        return date && moment(date).isValid()
            ? moment(date).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A';
    }

    return (
        <Tab.Pane>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Advisor</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>{area === 'prospect' ? 'Areas':'# Recs'}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {_items?.map(item => {
                        return <Table.Row data-stamps={item.timestamps ? JSON.stringify(item.timestamps) : '{}'}>
                            <Table.Cell>{item?.advisor?.fullName}</Table.Cell>
                            <Table.Cell>{item.client ? `${item.client.firstName} ${item.client.lastName}` : 'N/A'}</Table.Cell>
                            <Table.Cell>{dateFormatting(item.date)}</Table.Cell>
                            <Table.Cell>{area === 'prospect' ? item.data.areas.join(', ') :item.data.recommendations}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        </Tab.Pane>
    )
}

const Extractions = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [toggleFP, setToggleFP] = useState(false);

    const areasExtractions = useSelector(state => state.areasExtractions);

    useEffect(() => {
        //dispatch(fetchAreasExtractions(startDate, endDate));

    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(fetchAreasExtractions(startDate, endDate, !toggleFP));

    // eslint-disable-next-line
    }, [toggleFP]);

    const filterBtn_Click = () => {
        dispatch(fetchAreasExtractions(startDate, endDate));
    }

    const extractions = areasExtractions.map((item) => { 
        return {
            areaId: item.area, 
            items: item.data.map(i => {return {...i, advisor: i.advisorData, client: i.clientData}})
        }});

    const toggleFPInput = () => {
        setToggleFP(!toggleFP)
    }

    const panes = extractions.map(extr => ({
        menuItem: `${ALL_AREAS[extr.areaId]} (${extr.items.length})`,
        render: () => <AreaExtractions items={extr.items} />
    }));

    return (<>
        <Container fluid style={{ margin: '0 0 20px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ marginRight: '10px' }}>From</span>
                    <Input type='date' value={startDate} onChange={e => setStartDate(e.currentTarget.value)} />
                    <span style={{ margin: '0 10px' }}>to</span>
                    <Input type='date' value={endDate} onChange={e => setEndDate(e.currentTarget.value)} />
                    <Button style={{ marginLeft: '10px' }} color='grey' onClick={filterBtn_Click}>Filter</Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>Hide FP Alpha</span>
                    <Checkbox toggle checked={toggleFP} onChange={toggleFPInput} />
                </div>
            </div>
        </Container>
        <Container fluid>
            <Tab panes={panes} />
        </Container>
    </>)
}

const Recommendations = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [toggleFP, setToggleFP] = useState(false);

    const areasRecommendations = useSelector(state => state.areasRecommendations);

    useEffect(() => {
        console.log('here')
        dispatch(fetchAreasRecommendations(startDate, endDate));

    // eslint-disable-next-line
    }, []);

    useEffect(() => {

        dispatch(fetchAreasRecommendations(startDate, endDate, !toggleFP));

    // eslint-disable-next-line
    }, [toggleFP]);

    const filterBtn_Click = () => {
        dispatch(fetchAreasRecommendations(startDate, endDate));
    }

    const recommendations =  areasRecommendations.map((item) => { 
        return {
            areaId: item.area, 
            items: item.data.map(i => {return {...i, advisor: i.advisorData, client: i.clientData}})
        }});


    const toggleFPInput = () => {
        setToggleFP(!toggleFP)
    }

    const panes = recommendations.map(extr => ({
        menuItem: `${ALL_AREAS[extr.areaId]} (${extr.items.length})`,
        render: () => <AreaRecommendations items={extr.items} area={extr.areaId} />
    }));

    return (<>
        <Container fluid style={{ margin: '0 0 20px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ marginRight: '10px' }}>From</span>
                    <Input type='date' value={startDate} onChange={e => setStartDate(e.currentTarget.value)} />
                    <span style={{ margin: '0 10px' }}>to</span>
                    <Input type='date' value={endDate} onChange={e => setEndDate(e.currentTarget.value)} />
                    <Button style={{ marginLeft: '10px' }} color='grey' onClick={filterBtn_Click}>Filter</Button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>Hide FP Alpha</span>
                    <Checkbox toggle checked={toggleFP} onChange={toggleFPInput} />
                </div>
            </div>
        </Container>
        <Container fluid>
            <Tab panes={panes} />
        </Container>
    </>)
}

export const LastLogin = () => {
    const [activePage, setActivePage] = useState(1);
    // eslint-disable-next-line
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}&sort=lastLogin`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setRows(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {
            console.error(err);
        });

    // eslint-disable-next-line
    }, []);

    const pageChange = (e, { activePage }) => {
        setActivePage(activePage);
        sysAPI.get(`/advisors?page=${activePage}&size=${pageSize}&sort=lastLogin`).then(response => {
            const { advisorsList, totalPages } = response.data;
            setRows(advisorsList);
            setTotalPages(totalPages);
        }).catch(err => {

        });
    };

    const dateFormatting = (date) => {
        return date && moment(date).isValid()
            ? moment(date).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A';
    };

    return (<>
        <Container fluid>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Full Name</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Last Login Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map(row => {
                        return <Table.Row>
                            <Table.Cell>{row.fullName}</Table.Cell>
                            <Table.Cell>{row.email}</Table.Cell>
                            <Table.Cell>{dateFormatting(row.activity?.lastLogin || row.lastLogin)}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
            <Message color='blue'>N/A: Has not logged in since at least June 30th, 2021</Message>
            <Pagination totalPages={totalPages} activePage={activePage} onPageChange={pageChange} />
        </Container>
    </>)
}

export const StackedGraphic = ({ labels, datasets, options, plugins, datalabels }) => {
    return (<>
        <Bar
            plugins={[ChartDataLabels]}
            data={{ labels: labels, datasets: datasets }}
            options={{
                ...options,
                categoryPercentage: 1,
                type: 'bar',
                layout: {
                    padding: 20
                },
                color: 'black',
                scales: {
                    xAxis: {
                        ticks: {
                            color: "black",
                            font: {
                                size: 15,
                            },
                        },
                        categoryPercentage: 1.0,
                        barPercentage: 1.0
                    },
                    yAxis: {
                        ticks: {
                            font: {
                                size: 15,
                            },
                            color: "black",
                            padding: 5
                        }
                    }
                },
                plugins: {
                    datalabels: {
                        color: 'black',
                        font: {
                            family: 'sans-serif',
                            size: 15,
                        },
                        ...datalabels
                    },
                    ...plugins
                }
            }}

        />
    </>)
}

export const Graphic = ({ endpoint, title, options, plugins, datalabels, hasTable, dropdownOptions, isPeriod, activeIndex, areaOptions}) => {
    const [labels, setLables] = useState([]);
    const [datasets, setDataset] = useState([]);
    const [sublabels, setSublables] = useState([]);
    const [subdatasets, setSubdataset] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [table, setTable] = useState({});
    // eslint-disable-next-line
    const [typeOfAnalysis, setTypeOfAnalysis] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [excludeCompanies, setExcludeCompanies] = useState([]);
    const [withTable, setWithTable] = useState(false);
    const [withPeriod, setWithPeriod] = useState(false);
    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([]);
    const callAPI = () => {
        sysAPI.get(`/advisors/graphics/${endpoint}?startDate=${startDate}&endDate=${endDate}&isPeriod=true&companies=${companies?.join(',') || ''}&excludeCompanies=${excludeCompanies?.join(',') || ''}&areas=${areas?.join(',') || ''}`).then(response => {
            const { datasets, labels } = response.data;
            setDataset(datasets);
            setLables(labels);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
        if (withTable) {
            sysAPI.get(`/advisors/graphics/${endpoint}-table?startDate=${startDate}&endDate=${endDate}&isPeriod=true&companies=${companies?.join(',') || ''}&excludeCompanies=${excludeCompanies?.join(',') || ''}`).then(response => {
                const responseTable = response.data;
                setTable(responseTable);
            }).catch(err => {
                console.error(err);
            });
        }
        if (endpoint === 'weekly-processes') {
            sysAPI.get(`/advisors/graphics/${endpoint}-by-area?startDate=${startDate}&endDate=${endDate}&isPeriod=true&companies=${companies?.join(',') || ''}&excludeCompanies=${excludeCompanies?.join(',') || ''}`).then(response => {
                const { datasets, labels } = response.data;
                setSubdataset(datasets);
                setSublables(labels);
            }).catch(err => {
                console.error(err);
            });
            sysAPI.get(`/advisors/graphics/${endpoint}-analysis-type-table?startDate=${startDate}&endDate=${endDate}&isPeriod=true&companies=${companies?.join(',') || ''}&excludeCompanies=${excludeCompanies?.join(',') || ''}`).then(response => {
                const { typeOfAnalysis } = response.data;
                setTypeOfAnalysis(typeOfAnalysis);
            }).catch(err => {
                console.error(err);
            });
        }
        
    }
    const filterBtn_Click = () => {
        callAPI()
        if (!isPeriod) {
            setStartDate(moment(startDate).format('YYYY-MM-DD'))
            setEndDate(moment(endDate).format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        setLoading(true);
        setWithPeriod(isPeriod || false);
        setWithTable(hasTable || false);

    // eslint-disable-next-line
    }, [activeIndex]);

    useEffect(() => {
        callAPI();

    // eslint-disable-next-line
    }, [withPeriod, withTable]);

    return (<>
        {loading ? <Container style={{margin: '20px'}}>
                <Loader active/>
        </Container> :
        <Container>
        <Header textAlign='center' as='h2'>{title}</Header>
        <Container fluid style={{ margin: '10px 0' }} textAlign='center'>
            <span style={{ marginRight: '10px' }}>From</span>
            <Input type='date' value={startDate} onChange={e => setStartDate(e.currentTarget.value)} />
            <span style={{ margin: '0 10px' }}>to</span>
            <Input type='date' value={endDate} onChange={e => setEndDate(e.currentTarget.value)} />
            <span style={{ margin: '0 10px' }}></span>
        </Container>
        {endpoint === 'weekly-extractions' &&
            <Container textAlign='center' style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ margin: '0 10px' }}>Areas</label>
                    <Dropdown placeholder='Areas' multiple selection options={areaOptions} value={areas || []} onChange={(e, { value }) => setAreas(value)} />
                </div>
            </Container>
        }
        <Container textAlign='center' style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px' }}>Include</label>
                <Dropdown placeholder='Company' multiple selection options={dropdownOptions} value={companies || []} onChange={(e, { value }) => setCompanies(value)} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ margin: '0 10px' }}>Exclude</label>
                <Dropdown placeholder='Company' multiple selection options={dropdownOptions} value={excludeCompanies || []} onChange={(e, { value }) => setExcludeCompanies(value)} />
            </div>
            
        </Container>
        <Container textAlign='center'>
            <Button style={{ marginLeft: '10px' }} color='grey' onClick={filterBtn_Click}>Filter</Button>
        </Container>
        <StackedGraphic
            labels={labels}
            datasets={datasets}
            options={options}
            plugins={plugins}
            datalabels={datalabels}
        />
        {hasTable ? <UserActivity table={table} /> : null}
        {endpoint === 'weekly-processes' ?
            <>
                <Header textAlign='center' as='h3'>{'Week\'s User Activity by area'}</Header>
                <StackedGraphic
                    labels={sublabels}
                    datasets={subdatasets}
                    options={{ barPercentage: 0.85, }}
                    datalabels={{ anchor: 'end', align: 'end', offset: 5 }}
                    plugins={{ legend: { display: true, position: 'right', labels: { font: { size: 15 } } } }}
                />
                
            </>
            : null}
            </Container>}
    </>)
}


export const Tables = ({ title, dropdownOptions, areaOptions, metrics, activeIndex}) => {
    //historicProspectsConversion
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [companies, setCompanies] = useState([]);
    const [excludeCompanies, setExcludeCompanies] = useState([]);
    // eslint-disable-next-line
    const [withPeriod, setWithPeriod] = useState(false);
    const [loading, setLoading] = useState(true);
    const [areas, setAreas] = useState([]);

    const callAPI = () => {
        setLoading(true);
        sysAPI.get(`/metrics?metrics=${metrics}&startDate=${startDate}&endDate=${endDate}&isPeriod=${withPeriod}&companies=${companies?.join(',') || ''}&excludeCompanies=${excludeCompanies?.join(',') || ''}&areas=${areas?.join(',') || ''}`).then(response => {
            const d = response.data;
            setData(d);
            setLoading(false);
        }).catch(err => {
            console.error(err);
        });
        
    }
    const filterBtn_Click = () => {
        callAPI()
    }

    useEffect(() => {
        callAPI()

    // eslint-disable-next-line
    }, [activeIndex]);

    return (<>
        <Container>
            <Header textAlign='center' as='h2'>{title}</Header>
            <Container fluid style={{ margin: '10px 0' }} textAlign='center'>
                <span style={{ marginRight: '10px' }}>From</span>
                <Input type='date' value={startDate} onChange={e => setStartDate(e.currentTarget.value)} />
                <span style={{ margin: '0 10px' }}>to</span>
                <Input type='date' value={endDate} onChange={e => setEndDate(e.currentTarget.value)} />
                <span style={{ margin: '0 10px' }}></span>
            </Container>
        
            <Container textAlign='center' style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ margin: '0 10px' }}>Areas*</label>
                    <Dropdown placeholder='Areas' multiple selection options={areaOptions} value={areas || []} onChange={(e, { value }) => setAreas(value)} />
                </div>
            </Container>
        
            <Container textAlign='center' style={{ margin: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ marginRight: '10px' }}>Include</label>
                    <Dropdown placeholder='Company' multiple selection options={dropdownOptions} value={companies || []} onChange={(e, { value }) => setCompanies(value)} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ margin: '0 10px' }}>Exclude</label>
                    <Dropdown placeholder='Company' multiple selection options={dropdownOptions} value={excludeCompanies || []} onChange={(e, { value }) => setExcludeCompanies(value)} />
                </div>
                
            </Container>
            <Container textAlign='center'>
                <Button style={{ marginLeft: '10px' }} color='grey' onClick={filterBtn_Click}>Filter</Button>
            </Container>
        </Container>
        {loading ? 
        <Container style={{margin: '20px'}}>
            <Loader active/>
        </Container> :
        <Container>
            <Container style={{marginTop:'50px'}}>
                {data.map(metric => {
                    return (metric.type === 'graph' 
                    ?   <>
                        <Header textAlign='center' as='h3'>{metric.title}</Header>
                        <StackedGraphic
                            labels={metric.data.labels}
                            datasets={metric.data.datasets}
                            options={{ barPercentage: 0.3, indexAxis: 'y', elements: { bar: { borderWidth: 2, borderColor: 'white' } } }}
                            plugins={{ legend: { display: true, position: 'bottom', labels: { font: { size: 15 } } } }}
                        />
                        </>
                    : metric.type === 'table' 
                        ? <>
                            <Header textAlign='center' as='h3'>{metric.title}</Header>
                            <GenericTable 
                                rows={metric.data}
                                fields={metric.headers}
                            />
                        </>
                        : <>
                            <Header textAlign='center' as='h3'>{metric.title}</Header>
                            {metric.data.map(nestedTable => {
                                return <>
                                    <GenericTable 
                                        rows={nestedTable.data}
                                        title={nestedTable.area}
                                        fields={nestedTable.headers}
                                    />
                                </>
                            })}
                        </>
                )})}
            </Container>
        </Container>}
    </>)
}

export const UserActivity = ({ table }) => {
    return (
        <>
            {table?.subscribed?.length > 0 ?
                <GraphicTable rows={table.subscribed || []} title={'Paid'} /> :
                null}
            {table?.trial?.length > 0 ?
                <GraphicTable rows={table.trial || []} title={'Trial'} /> :
                null}
        </>)
}

export const GraphicTable = ({ rows, title }) => {
    const dateFormatting = (date) => {
        return date && moment(date).isValid()
            ? moment(date).format('MM/DD/YYYY HH:mm:ss')
            : 'N/A';
    }

    return (<>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='4' textAlign='center'>{title}</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Complete Processes</Table.HeaderCell>
                    <Table.HeaderCell>Most Used Area</Table.HeaderCell>
                    <Table.HeaderCell># of Logins</Table.HeaderCell>
                    <Table.HeaderCell>Last Login</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows.map(row => {
                    return <Table.Row>
                        <Table.Cell>{row.advisor}</Table.Cell>
                        <Table.Cell>{row.completeProcesses}</Table.Cell>
                        <Table.Cell>{row.area?.join('/') || 'report'}</Table.Cell>
                        <Table.Cell>{row.logins || 0}</Table.Cell>
                        <Table.Cell>{dateFormatting(row.lastLogin)}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </>)
}

export const GenericTable = ({ rows, title, fields }) => {
    const getFields = () => {
        const auxfields = [];
        for (const row of fields) {
            for (const field of row) {
                if (!field.size)
                auxfields.push(field.value)
            }
        }
        return auxfields;
    }
    const getSize = () => {
        let size = 0;
        for (const field of fields[0]) {
            size+=field.size || 1;
        }
        return size;
    }
    return (<>
        <Table>
            <Table.Header>
                {title && <Table.Row>
                    <Table.HeaderCell colSpan={getSize()} textAlign='center'>{title}</Table.HeaderCell>
                </Table.Row>}
                {fields.map(row => {
                return <Table.Row>
                    {row.map(field => {
                        return  <Table.HeaderCell rowSpan={field.size ? 1: fields.length} colSpan={field.size || 1}>{field.name}</Table.HeaderCell>
                    })}
                </Table.Row>
                })}
            </Table.Header>
            <Table.Body>
                {rows.map(row => {
                    return <Table.Row>
                        {getFields().map(field => {
                            return  <Table.Cell> {row[field]}</Table.Cell>
                        })}
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    </>)
}

export const Graphics = () => {
    const [companiesList, setCompaniesList] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            setCompaniesList(companiesList.map(company => ({ key: company, text: company, value: company })));
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const handleChange =  (e, data) => {
        setActiveIndex(data.activeIndex);
    }

    const panes = [
        {
            menuItem: 'Usage',
            render: () => <Tab.Pane>
                <Graphic
                    activeIndex={activeIndex}
                    endpoint={'weekly-processes'}
                    hasTable={true}
                    title={'Activity by period'}
                    options={{ barPercentage: 0.3, elements: { bar: { borderWidth: 2, borderColor: 'white' } } }}
                    plugins={{ legend: { display: true, position: 'bottom', labels: { font: { size: 15 } } } }}
                    dropdownOptions={companiesList}
                    isPeriod={true}
                />
            </Tab.Pane>,
        },
        {
            menuItem: 'Tables',
            render: () => <Tab.Pane>
                <Tables
                    activeIndex={activeIndex}
                    title={'Week\'s User Activity'}
                    metrics={'validVsInvalidProcesses,typeOfAnalysis,avgProcessingTime,returnFlags,reasonOfFailure'}
                    dropdownOptions={companiesList}
                    areaOptions={Object.keys(ALL_AREAS).map( area => {return { key: area, text: ALL_AREAS[area], value: area }})}
                />
            </Tab.Pane>,
        },
        {
            menuItem: 'Prospects',
            render: () => <Tab.Pane>
                <Tables
                    activeIndex={activeIndex}
                    title={'Week\'s User Activity'}
                    metrics={'historicProspectsConversion,prospectsActivity'}
                    dropdownOptions={companiesList}
                    areaOptions={Object.keys(ALL_AREAS).map( area => {return { key: area, text: ALL_AREAS[area], value: area }})}
                />
            </Tab.Pane>,
        }
    ]

    return (<>
        <Container fluid>
            <Tab menu={{ pointing: true, secondary: true, }} panes={panes} onTabChange={handleChange} />
        </Container>
    </>)
}


export const Metrics = () => {
    const [companiesList, setCompaniesList] = useState([]);

    // eslint-disable-next-line
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            setCompaniesList(companiesList.map(company => ({ key: company, text: company, value: company })));
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const handleChange =  (e, data) => {
        setActiveIndex(data.activeIndex);
    }

    const panes = [
        {
            menuItem: 'Usage',
            render: () => <Tab.Pane>
                <Tables
                    title={'Week\'s User Activity'}
                    dropdownOptions={companiesList}
                    areaOptions={Object.keys(ALL_AREAS).map( area => {return { key: area, text: ALL_AREAS[area], value: area }})}
                />
            </Tab.Pane>,
        }
    ]

    return (<>
        <Container fluid>
            <Tab menu={{ pointing: true, secondary: true, }} panes={panes} onTabChange={handleChange} />
        </Container>
    </>)
}

export const DashboardMetricsView = () => {
    const panes = [
        { menuItem: 'Extractions by area', render: () => <Extractions /> },
        { menuItem: 'Recommendations by area', render: () => <Recommendations /> },
        { menuItem: 'Last login', render: () => <LastLogin /> },
        { menuItem: 'Graphics', render: () => <Graphics /> },
    ];

    return (
        <DashboardTemplate scope='metrics' permission="metrics:metrics_view">
            <TabContainer
                title='Platform Metrics'
                subtitle='Registry of advisors activity'
                icon='chart pie'
            >
                <Tab panes={panes} menu={{ fluid: true, vertical: true, }} grid={{ paneWidth: 13, tabWidth: 3 }} style={{ marginTop: '20px' }} />
            </TabContainer>
        </DashboardTemplate>
    )
}