import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

export const DashboardMenu = ({ activeMenuItem }) => {
    const history = useHistory();
    const me = useSelector(state => state.me);

    const displayStyle = (permission) => {
        return me?.permissions?.includes(permission) ? 'block' : 'none';
    }

    const routes = [
        {
            activeMenuItem: "Advisors",
            name: "Advisors",
            to: "/dashboard/advisors",
            permission: "advisors:advisor_view"
        },
        {
            activeMenuItem: "Clients",
            name: "Clients",
            to: "/dashboard/clients",
            permission: "clients:client_view"
        },
        {
            activeMenuItem: "Companies",
            name: "Companies",
            to: "/dashboard/companies",
            permission: "companies:company_view"
        },
        {
            activeMenuItem: "Sales",
            name: "Sales",
            to: "/dashboard/sales",
            permission: "sales:sales_view"
        },
        {
            activeMenuItem: "Metrics",
            name: "Usage Metrics",
            to: "/dashboard/metrics",
            permission: "metrics:metrics_view"
        },
        {
            activeMenuItem: "Manual Input",
            name: "Manual Input",
            to: "/dashboard/manual-input",
            permission: "manual_inputs:manual_input_view"
        },
        {
            activeMenuItem: "Resources",
            name: "Resources",
            to: "/dashboard/resources",
            permission: "resourses:resourse_view"
        },
        {
            activeMenuItem: "Admin",
            name: "Admin",
            to: "/dashboard/admin",
            permission: "admin:admin_view"
        },
        {
            activeMenuItem: "Roles",
            name: "Roles",
            to: "/dashboard/admin-roles",
            permission: "roles:rol_view"
        },
        {
            activeMenuItem: "Quickbooks",
            name: "Admin QuickBooks",
            to: "/dashboard/quickbooks",
            permission: "admin_quickbooks:admin_quickbook_view"
        },
        {
            activeMenuItem: "AdvisorsSnapshots",
            name: "Snapshots",
            to: "/dashboard/advisors-snapshots",
            permission: "snapshots:snapshots_view"
        },
        {
            activeMenuItem: "Saml",
            name: "Saml",
            to: "/dashboard/integrations-saml",
            permission: "saml:saml_view"
        },
        {
            activeMenuItem: "RolesEnterpriseSettings",
            name: "Roles (ES)",
            to: "/dashboard/roles-enterprise-settings",
            permission: "enteprise_settings:roles_view"
        },
    ]

    return (
        <Menu attached='top'>
            {routes.map((route,index) => (
                <Menu.Item
                    key={index}
                    active={activeMenuItem === route.activeMenuItem}
                    name={route.name}
                    onClick={() => history.push(`${route.to}`)}
                    style={{ display: displayStyle(route.permission) }}
                />
            ))}
        </Menu>
    )
}